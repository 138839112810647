type PlanName =
  | 'Free'
  | 'Basic'
  | 'Pro'
  | 'Expert'
  | 'Enterprise'
  | 'Enterprise - MSA'
  | 'Advanced'
  | 'Appsumo Tier 1'
  | 'Appsumo Tier 2'
  | 'Appsumo Tier 3';

export type PlanIds =
  | 1
  | 21
  | 22
  | 31
  | 32
  | 33 //TODO: Fake plan. Remove after data-source addon is released
  | 41
  | 42
  | 43 //TODO: Fake plan. Remove after data-source addon is released
  | 44 //TODO: Fake plan. Remove after data-source addon is released
  | 51
  | 61
  | 62
  | 63 //TODO: Fake plan. Remove after data-source addon is released
  | 91
  | 92
  | 93
  | 101;

export const planIdToName: {
  [key in PlanIds]: PlanName;
} = {
  1: 'Free',
  21: 'Basic',
  22: 'Basic',
  31: 'Pro',
  32: 'Pro',
  33: 'Pro', //TODO: Fake plan. Remove after data-source addon is released
  41: 'Expert',
  42: 'Expert',
  43: 'Expert', //TODO: Fake plan. Remove after data-source addon is released
  44: 'Expert', //TODO: Fake plan. Remove after data-source addon is released
  51: 'Enterprise',
  61: 'Advanced',
  62: 'Advanced',
  63: 'Advanced', //TODO: Fake plan. Remove after data-source addon is released
  91: 'Appsumo Tier 1',
  92: 'Appsumo Tier 2',
  93: 'Appsumo Tier 3',
  101: 'Enterprise - MSA',
};

export const planIdToNameColor: {
  [key: number]: {
    name: string;
    color: string;
    textColor: string;
    style?: string;
  };
} = {
  0: { name: 'Agency', color: '#000000', textColor: '#ffffff' },
  1: {
    name: 'Free',
    color: 'transparent',
    textColor: '#6b7280',
    style: 'border border-gray-200',
  },
  21: { name: 'Basic', color: '#e5e7eb', textColor: '#1f2937' },
  22: { name: 'Basic', color: '#e5e7eb', textColor: '#1f2937' },
  31: { name: 'Pro', color: '#dbeafe', textColor: '#2563eb' },
  32: { name: 'Pro', color: '#dbeafe', textColor: '#2563eb' },
  33: { name: 'Pro', color: '#dbeafe', textColor: '#2563eb' }, //TODO: Fake plan. Remove after data-source addon is released
  41: { name: 'Expert', color: '#dcfce7', textColor: '#166534' },
  42: { name: 'Expert', color: '#dcfce7', textColor: '#166534' },
  43: { name: 'Expert', color: '#dcfce7', textColor: '#166534' }, //TODO: Fake plan. Remove after data-source addon is released
  44: { name: 'Expert', color: '#dcfce7', textColor: '#166534' }, //TODO: Fake plan. Remove after data-source addon is released
  51: { name: 'Enterprise', color: '#fee2e2', textColor: '#991b1b' },
  61: { name: 'Advanced', color: '#f3e8ff', textColor: '#6b21a8' },
  62: { name: 'Advanced', color: '#f3e8ff', textColor: '#6b21a8' },
  63: { name: 'Advanced', color: '#f3e8ff', textColor: '#6b21a8' }, //TODO: Fake plan. Remove after data-source addon is released
  91: { name: 'Lifetime', color: '#e5e7eb', textColor: '#1f2937' },
  92: { name: 'Lifetime', color: '#dbeafe', textColor: '#2563eb' },
  93: { name: 'Lifetime', color: '#dcfce7', textColor: '#166534' },
  101: { name: 'Enterprise - MSA', color: '#fee2e2', textColor: '#991b1b' },
};

export const FREE_PLAN_ID: PlanIds = 1;
export const BASIC_PLAN_IDS: PlanIds[] = [21, 22];
export const EXPERT_PLAN_IDS: PlanIds[] = [41, 42, 43, 44];
export const ENTERPRISE_PLAN_IDS: PlanIds[] = [51, 101];
export const ADVANCED_PLAN_IDS: PlanIds[] = [61, 62, 63];
export const APPSUMO_PLAN_IDS: PlanIds[] = [91, 92, 93];

export const PlanDescription: { [key in PlanName]: string } = {
  Free: '"perfect for personal use"',
  Basic: '"perfect for personal use"',
  Pro: '"perfect for small businesses"',
  Advanced: '"perfect for mid-sized businesses"',
  Expert: '"perfect for scaling businesses"',
  Enterprise: '"perfect for enterprises"',
  'Enterprise - MSA': '"perfect for enterprises"',
  'Appsumo Tier 1': '"ideal for Appsumo Tier 1 users"',
  'Appsumo Tier 2': '"ideal for Appsumo Tier 2 users"',
  'Appsumo Tier 3': '"ideal for Appsumo Tier 3 users"',
};

type PLAN_VARIANTS = 'MONTHLY' | 'YEARLY';
export type AvailablePlan = {
  name: string;
  description: string;
  variants: {
    MONTHLY: {
      dbPlanId: number;
      price: number | null;
      stripePriceId: string | null;
    };
    YEARLY: {
      dbPlanId: number;
      price: number | null;
      stripePriceId: string | null;
    };
  };
  features: string[];
};
export type StaticAvailablePlan = {
  name: PlanName;
  description: string;
  variants: {
    [key in PLAN_VARIANTS]: {
      dbPlanId: PlanIds;
    };
  };
  features: string[];
};

export const STATIC_AVAILABLE_PLANS: StaticAvailablePlan[] = [
  {
    name: 'Basic',
    description: 'Personal use',
    variants: {
      MONTHLY: {
        dbPlanId: 21,
      },
      YEARLY: {
        dbPlanId: 22,
      },
    },
    features: [
      '4.000 Messages (GPT-4o)',
      '5 Million Characters',
      '1 Seat',
      '1 Chatbot',
      'Live Chat',
    ],
  },
  {
    name: 'Pro',
    description: 'Small business',
    variants: {
      MONTHLY: {
        dbPlanId: 31,
      },
      YEARLY: {
        dbPlanId: 32,
      },
    },
    features: [
      '10.000 Messages (GPT-4o)',
      '10 Million Characters',
      '1 Seat',
      '3 Chatbots',
      'Live Chat',
      'AI Actions',
    ],
  },
  {
    name: 'Advanced',
    description: 'Mid-sized business',
    variants: {
      MONTHLY: {
        dbPlanId: 61,
      },
      YEARLY: {
        dbPlanId: 62,
      },
    },
    features: [
      '15.000 Messages (GPT-4o)',
      '10 Million Characters',
      '3 Seats',
      '5 Chatbots',
      'Live Chat',
      'AI Actions',
      'Llama, Mistral & Claude',
      'Make.com',
      'API Access',
      'Slack',
    ],
  },
  {
    name: 'Expert',
    description: 'Scaling business',
    variants: {
      MONTHLY: {
        dbPlanId: 41,
      },
      YEARLY: {
        dbPlanId: 42,
      },
    },
    features: [
      '20.000 Messages (GPT-4o)',
      '10 Million Characters',
      '6 Seats',
      '10 Chatbots',
      'Live Chat',
      'AI Actions',
      'Llama, Mistral & Claude',
      'Make.com',
      'API Access',
      'Slack',
      'Whatsapp',
      'Remove Branding',
    ],
  },
];

export const AUTO_RESYNC_ADDON_DB_ID = 4;
